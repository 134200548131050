<script>
import {defineComponent} from 'vue'
import DateAreaPicker from "../components/DateAreaPicker";

export default defineComponent({
  name: "DirectMarketingEditor",
  props: {
    id: Number,
    mode: String,
  },
  components: {
    DateAreaPicker
  },
  data(){
    return {
      campaign: {
        text: "",
        max_sms_count_per_week: 14,
        max_sms_count_per_day: 7,
        start_send_date: null,
        end_send_date: null,
        start_send_time: '12:00',
        end_send_time: '13:00',
        hide_from_agent: false,
        project: [],
      },
      selected_agent: null,
      search_agent: "",
      postText: "",
      projects_list: [],
      subprojects_list: [],
      agents_list: [],
      apiCall: false,
      countApi: false, //if must externally load the count for selection
      //menus
      menuGroups: {
        'sms_marketing': [],
        'sms_service_numbers': [],
        'ad_attribute': [],
        'categories': [],
        'groups': [],
        'audio_services': []
      },
      menuGroupsSelectionCheckboxes: {
        'sms_marketing': [],
        'sms_service_numbers': [],
        'ad_attribute': [],
        'categories': [],
        'groups': [],
        'audio_services': [],
      },
      menuGroupsSelections: [],
      menuGroupsSelectionsCountSumVal: 0,
      startDate: null, //shared by all menus
      endDate: null, //shared by all menus
      menuKeyword: null,
      campaignMenu: false,
      groupMenu: false,
      smsServiceNumbersMenu: false,
      adsMenu: false,
      categoriesMenu: false,
      audioServicesMenu: false,

      missingCustomersMenu: false,
      customNumberMenu: false,
      inactiveAudience: null,
      inactiveContact: null,
      manualNumber: null,
      submitSuccess: null,
      submitError: null,
      testNumber: '0400904492',

      snackbar: false,
      snackbarTimeout: 5000,
      snackbarMessage: null,
      snackbarColor: null,
      sendingTest: false,
      forums: []
    }
  },
  watch: {
    postText(newvalue, oldvalue){
      if(this.postText){
        // Remove the old value from the end of the string
        const index = this.campaign.text.lastIndexOf(oldvalue);
        if (index !== -1) {
          this.campaign.text = this.campaign.text.substring(0, index);
        }

        // Remove trailing whitespace
        this.campaign.text = this.campaign.text.trim()

        // Add the new value to the end of the string
        this.campaign.text += ' ' + newvalue;
      }
    },
    audioServicesMenu(){
      this.menuGroups.categories.length == 0 ? this.getMenu('sms_marketing/audio_services', 'audio_services') : null
      this.audioServicesMenu ? this.closeOthers('audio_services') : null
    },
    categoriesMenu(){
      this.menuGroups.categories.length == 0 ? this.getMenu('sms_marketing/categories', 'categories') : null
      this.categoriesMenu ? this.closeOthers('categories') : null
    },
    adsMenu(){
      this.menuGroups.ad_attribute.length == 0 ? this.getMenu('sms_marketing/ad_attribute', 'ad_attribute') : null
      this.adsMenu ? this.closeOthers('ad_attribute') : null
    },
    smsServiceNumbersMenu(){
      this.menuGroups.sms_service_numbers.length == 0 ? this.getMenu('sms_marketing/sms_service_numbers', 'sms_service_numbers') : null
      this.smsServiceNumbersMenu ? this.closeOthers('sms_service_numbers') : null
    },
    campaignMenu(){
      this.menuGroups.sms_marketing.length == 0 ? this.getMenu('sms_marketing', 'sms_marketing') : null
      this.campaignMenu ? this.closeOthers('sms_marketing') : null
    },
    groupMenu(){
      this.menuGroups.groups.length == 0 ? this.getMenu('sms_marketing/groups', 'groups') : null
      this.groupMenu ? this.closeOthers('groups') : null
    },
    customNumberMenu(){
      this.customNumberMenu ? this.closeOthers('custom_numbers_menu') : null
    },
    missingCustomersMenu(){
      this.missingCustomersMenu ? this.closeOthers('missing_customers_menu') : null
    },
  },
  methods: {
    sendTestMessage(){

      this.sendingTest = true
      this.axios.get('sms_marketing/test_send', {params: {
        sender: 'Jaloviina',
        receiver: this.testNumber,
        message: this.campaign.text
        }}).then(()=>{
          this.sendingTest = false
      })
    },
    submit(){

      //check errors
      if(this.menuGroupsSelections.length == 0 && this.mode != 'edit'){
        this.snackbarMessage = "Valitse ainakin yksi vastaanottaryhmä"
        this.snackbar = true
        this.snackbarColor = 'red'

        return
      }

      if(!this.$refs.form.validate()) {

        this.snackbarMessage = "Virheitä lomakkeessa. Selaa ylös, korjaa virheet ja yritä tallennusta uudelleen. Kiitti"
        this.snackbar = true
        this.snackbarColor = 'red'

        return
      }

      this.submitError = null
      this.submitSuccess = null
      //define action
      let action = 'post'
      let url = 'sms_marketing'

      if(this.mode == 'edit'){
        action = 'put'
        url = 'sms_marketing/'+this.campaign.id
      }

      //store campaign sender for later use
      if(this.campaign.sender) localStorage.setItem("campaignSender", this.campaign.sender);

      //add post to text
      //this.campaign.text += ' '+this.postText

      this.apiCall = true
      this.campaign.receiver_groups = this.menuGroupsSelections
      this.axios[action](url, this.campaign).then(() => {
        if(this.mode == 'edit') this.submitSuccess = "Kampanja muokattiin onnistuneesti"
        else
        this.submitSuccess = "Kampanja luotiin onnistuneesti"
        this.apiCall = false
      }).catch(e => {
        this.submitError = "Virhe lähettäessä "+e
        this.snackbarMessage = "Virhe lähettäessä "+e
        this.snackbar = true
        this.snackbarColor = 'red'
      })

    },
    closeOthers(notMe){
      console.log('closeOthers', notMe)
      if(notMe != 'sms_marketing') this.campaignMenu = false
      if(notMe != 'groups') this.groupMenu = false
      if(notMe != 'audio_services') this.audioServicesMenu = false
      if(notMe != 'categories') this.categoriesMenu = false
      if(notMe != 'sms_service_numbers') this.smsServiceNumbersMenu = false
      if(notMe != 'ad_attribute') this.adsMenu = false
      if(notMe != 'custom_numbers_menu') this.customNumberMenu = false
      if(notMe != 'missing_customers_menu') this.missingCustomersMenu = false
    },
    async getMenu(api, name, keyword){
      this.apiCall = true
      await this.axios.get(api, {params: {from: this.startDate, to: this.endDate,keyword: keyword}})
          .then((response)=>{
            this.menuGroups[name] = response.data
          })
      this.apiCall = false
    },
    selection(item, group, key){
      let checkboxValue = this.menuGroupsSelectionCheckboxes[group][key]

      //if checbox is true, add to s
      if(checkboxValue){
        let pushItem = {}
        pushItem.id = item.id
        pushItem.count = item.count
        item.text ? pushItem.name = item.text : null
        item.name ? pushItem.name = item.name : null
        item.info ? pushItem.name = item.info : null
        pushItem.group = group
        pushItem.checkbox_key = key
        pushItem.from = this.startDate
        pushItem.to = this.endDate
        this.menuGroupsSelections.push(pushItem)
        //if item.count is null, get it if any
        if(!pushItem.count) this.getItemCount(pushItem)
      }
      else{
        const index = this.menuGroupsSelections.findIndex(x => item && item.id === x.id && item.group === x.group);
        if (index !== -1) {
          this.menuGroupsSelections.splice(index, 1);
        }
      }

      this.menuGroupsSelectionsCountSum()
    },
    deleteFromMenuGroupsSelections(selection){
      const index = this.menuGroupsSelections.findIndex(item => item && selection.id === item.id && selection.group === item.group);
      if (index !== -1) {
        this.menuGroupsSelections.splice(index, 1);
      }
      this.menuGroupsSelectionCheckboxes[selection.group][selection.checkbox_key] = false

      this.menuGroupsSelectionsCountSum()
    },
    getItemCount(item){
      this.countApi = true
      this.axios.get('sms_marketing/search_ehto', {params: {
          ehto_type: item.group.toUpperCase(),
          from: this.startDate,
          to: this.endDate,
          params: item.id,
          type: item.type,
          customer_type: item.contact_type,
      }
      }).then(response => {
        this.countApi = false
        const index = this.menuGroupsSelections.findIndex(x => item && item.id === x.id && item.group === x.group);
        if (index !== -1) {
          this.menuGroupsSelections[index].count = response.data.count
          this.menuGroupsSelectionsCountSum()
        }
      }).catch(() => {this.countApi = false})

    },
    menuGroupsSelectionsCountSum() {
      console.log('menuGroupsSelectionsCountSum')
      let sum = 0;
      for (const group of this.menuGroupsSelections) group && group.count ? sum += group.count : null
      this.menuGroupsSelectionsCountSumVal = sum
      if(!this.menuGroupsSelectionsCountSumVal) this.menuGroupsSelectionsCountSumVal = '?'
    },
  },
  computed: {

    campaignMessageError(){
      return this.campaign?.text.length > 150 ? ['Kaksi viestiä'] : null
    },
    availableSubprojects(){

      if(this.campaign.project) return this.subprojects_list.filter(p => p.project.id === this.campaign.project.id )

      return this.subprojects_list
    }
  },
  mounted(){
    if(this.mode == 'edit' || this.mode == 'copy')
      this.axios.get('/sms_marketing/'+this.id)
          .then((response)=>{
            //fix start and end dates
            if(response.data.start_send_date) response.data.start_send_date = response.data.start_send_date.substr(0,8)
            if(response.data.end_send_date) response.data.end_send_date = response.data.end_send_date.substr(0,8)
            this.campaign = response.data
            if(this.mode == 'copy') this.campaign.id = null
          })

    if(!this.mode){
      this.campaign.start_send_date = this.$day().format("DD.MM.YY")
      this.campaign.end_send_date = this.$day().format("DD.MM.YY")
    }

    //prefetch lists
    this.axios.get('reports/projects_list').then((r) => {this.projects_list = r.data}) //get projects_list
    this.axios.post('reports/subprojects_list', {projects: []}).then((r) => {this.subprojects_list = r.data}) //subprojects
    this.axios.get('reports/agent_list').then((r) => {this.agents_list = r.data}) //get agents_list
    this.axios.get('sms_marketing/forums_list').then((r) => {this.forums = r.data}) //get forums

    this.startDate = this.$day().subtract(28, 'day').format("DD.MM.YYYY")
    this.endDate = this.$day().format("DD.MM.YYYY")

    var campaignSender = localStorage.getItem("campaignSender");
    if(campaignSender) this.campaign.sender = campaignSender

  }
})
</script>

<template>
  <main style="padding-bottom: 430px;">
    <h1 class="headline blue--text text-center mt-4 mb-3">SMS Suoramarkkinointikampanja</h1>
    <v-divider></v-divider>

    <!-- 1. message base -->
    <h2 class="mt-5">Luo viesti</h2>
    <v-form
        class="form-element"
        ref="form"
    >
      <v-textarea
          label="Viesti"
          rows="3"
          placeholder="Kirjoita viesti asiakkaalle"
          outlined
          v-model="campaign.text"
          counter
          :error-messages="campaignMessageError"
          :rules='[(v) => !!v || "Ei voi olla tyhjä"]'
      />
      <v-row>
        <v-col>
          <v-select
              v-model="postText"
              label="viestin häntä"
              outlined
              :items="[
                  'heliad.fi',
                  'ennustajat.fi',
                  'paratiisi.fi'
                  ]"
          />
        </v-col>
        <v-col>
          <v-text-field v-model="postText" label="kustomoi häntää halutessasi" outlined/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-autocomplete
              v-model="campaign.agent_id"
              :search-input.sync="search_agent"
              :items="agents_list"
              item-value="agent_id"
              item-text="name"
              label="Valitse agentti jos haluat luoda agenttikohtaisen mainoksen"
              clearable
              outlined
          >
            <template #item="{ item }">
              {{ item.name }}
            </template>
            <template #selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-center" cols="4">
          <v-checkbox label="Lähetä heti. Valitse jos haluat viestittää heti." v-model="campaign.send"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <date-area-picker :input="campaign.start_send_date" parse-input="DD.MM.YY" @pick="campaign.start_send_date = $event" label="alkupäivä" :disabled="campaign.send"/>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="campaign.start_send_time" label="Lähetyksen alku klo aika" :disabled="campaign.send" outlined/>
        </v-col>
        <v-col cols="3">
          <date-area-picker :input="campaign.end_send_date" parse-input="DD.MM.YY" @pick="campaign.end_send_date = $event" label="loppupäivä" :disabled="campaign.send"/>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="campaign.end_send_time" label="Lähetyksen loppu klo aika" :disabled="campaign.send" outlined/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
              v-model="campaign.project"
              label="Projekti"
              outlined
              :items="projects_list"
              clearable
          >
            <template #item="{ item }">{{ item.name }}</template>
            <template #selection="{ item }">{{ item.name }}</template>
          </v-select>
        </v-col>
        <v-col>
          <v-select
              v-model="campaign.sub_project_id"
              label="Aliprojekti"
              outlined
              item-value="id"
              :items="availableSubprojects"
              :return-object="false"
              :rules='[(v) => !!v || "Täytä tämä"]'
              clearable
          >
            <template #item="{ item }">{{ item.name }}</template>
            <template #selection="{ item }">{{ item.name }}</template>
          </v-select>
        </v-col>
      </v-row>
      <v-text-field v-model="campaign.sender" label="Lähetysnumero" outlined :rules='[(v) => !!v || "Ei voi olla tyhjä"]'/>
      <v-row dense>
        <v-col>
          <v-text-field v-model="campaign.max_sms_count_per_week" label="Maksimi määrä viestejä viikossa" outlined type="number"/>
        </v-col>
        <v-col>
          <v-text-field v-model="campaign.max_sms_count_per_day" label="Maksimi määrä viestejä päivässä" outlined type="number"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-select
              v-model="campaign.forums"
              label="Foorumit"
              outlined
              :items="forums"
              clearable
              multiple
              return-object
              item-value="id"
              item-text="name"
          >
          </v-select>
        </v-col>
        <v-col>
          <v-checkbox v-model="campaign.hide_from_agent" label="Piilota kampanja agenttien työasemalla"/>
        </v-col>
      </v-row>
      <v-divider/>
      <h2 class="mt-5 mb-4">Viestin testilähetys</h2>
      <v-row>
        <v-col cols="2">
          <v-text-field v-model="testNumber" label="Lähetä testi numeroon" outlined/>
        </v-col>
        <v-col cols="4">
          <v-btn
              class="v-btn ma-3 green"
              small
              @click="sendTestMessage"
              :disabled="sendingTest"
          >
            <template v-if="sendingTest">Lähetetään...</template>
            <template v-else>Lähetä</template>
          </v-btn>
        </v-col>
      </v-row>

      <!-- 2. Test message (optional) -->
      <v-divider/>
      <h2 class="mt-5 mb-4">Valitse vastaanottajat</h2>

      <!-- 3. Receiver groups -->
      <v-row>
        <v-col cols="6">
          <h3>Vastaanottajaryhmät:</h3>

          <!-- aiemmat kampanjat -->
          <v-btn
              @click="campaignMenu?campaignMenu=false:campaignMenu=true"
              class="not-selected-btn"
              :class="{'selected-btn': campaignMenu}"
              aria-label="Aiemmat kampanjat"
              width="100%"
          >
            Aiemmat kampanjat
            <v-icon small>mdi-arrow-bottom-right</v-icon>
          </v-btn>
          <!--<transition name="roll" mode="out-in">-->
            <div class="mt-2" v-if="campaignMenu">
              <v-row>
                <v-col cols="3">
                  Kampanjat aikaväliltä:
                </v-col>
                <v-col cols="3">
                  <date-area-picker :input="startDate" parse-input="DD.MM.YYYY" @pick="startDate = $event" label="alku"/>
                </v-col>
                <v-col cols="3">
                  <date-area-picker :input="endDate" parse-input="DD.MM.YYYY" @pick="endDate = $event" label="loppu"/>
                </v-col>
                <v-col>
                  <v-btn
                      class="v-btn ma-3 green float-right"
                      small
                      @click="getMenu('sms_marketing', 'sms_marketing')"
                  >
                    Hae
                  </v-btn>
                </v-col>
              </v-row>

              <v-skeleton-loader
                  v-if="apiCall"
                  class="mx-auto"
                  type="article"
              />

              <v-simple-table v-if="menuGroups.sms_marketing.length > 0 && !apiCall" height="300px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Kampanja
                      </th>
                      <th class="text-left">
                        Lähetysaika
                      </th>
                      <th>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(menu, key) in menuGroups['sms_marketing']" :key="'menu1'+key" :class="{'secondary-row': key % 2 === 0, 'selected-row': key % 2 === 0 && menuGroupsSelectionCheckboxes.sms_marketing[key], 'selected-row2': key % 1 === 0 && menuGroupsSelectionCheckboxes.sms_marketing[key]}">
                      <td>
                        <label :for="'sms_marketing'+key">
                          {{ menu.text }}
                        </label>
                      </td>
                      <td>
                        <label :for="'sms_marketing'+key">
                          {{ menu.start_send_date }}
                        </label>
                      </td>
                      <td>
                        <v-checkbox v-model="menuGroupsSelectionCheckboxes.sms_marketing[key]" @click="selection(menu, 'sms_marketing', key)" :id="'sms_marketing'+key"></v-checkbox>
                      </td>
                    </tr>
                </tbody>
                </template>
              </v-simple-table>
            </div>
          <!--</transition>-->

          <!-- ryhmät groups -->
          <v-btn
              @click="groupMenu?groupMenu=false:groupMenu=true"
              class="not-selected-btn2 mt-1"
              :class="{'selected-btn': groupMenu}"
              aria-label="Ryhmät"
              width="100%"
          >
            Ryhmät
            <v-icon small>mdi-arrow-bottom-right</v-icon>
          </v-btn>
          <div class="mt-2" v-if="groupMenu">
            <v-row>
              <v-col cols="3">
                Soittajat ryhmiin aikaväliltä:
              </v-col>
              <v-col cols="3">
                <date-area-picker :input="startDate" parse-input="DD.MM.YYYY" @pick="startDate = $event" label="alku"/>
              </v-col>
              <v-col cols="3">
                <date-area-picker :input="endDate" parse-input="DD.MM.YYYY" @pick="endDate = $event" label="loppu"/>
              </v-col>
              <v-col>
                <v-btn
                    class="v-btn ma-3 green float-right"
                    small
                    @click="getMenu('sms_marketing/groups', 'groups')"
                >
                  Hae
                </v-btn>
              </v-col>
            </v-row>

            <v-skeleton-loader
                v-if="apiCall"
                class="mx-auto"
                type="article"
            />

            <v-simple-table v-if="menuGroups.groups.length > 0 && !apiCall" height="300px">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Ryhmä
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(menu, key) in menuGroups['groups']" :key="'menu2'+key" :class="{'secondary-row': key % 2 === 0, 'selected-row': key % 2 === 0 && menuGroupsSelectionCheckboxes.groups[key], 'selected-row2': key % 1 === 0 && menuGroupsSelectionCheckboxes.groups[key]}">
                  <td>
                    <label :for="'groups2'+key">
                      {{ menu.name }} {{ menu.description }}
                    </label>
                  </td>
                  <td>
                    <v-checkbox v-model="menuGroupsSelectionCheckboxes.groups[key]" @click="selection(menu, 'groups', key)" :id="'groups2'+key"></v-checkbox>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <!-- palvelut audio_Services -->
          <v-btn
              @click="audioServicesMenu?audioServicesMenu=false:audioServicesMenu=true"
              class="not-selected-btn mt-1"
              :class="{'selected-btn': audioServicesMenu}"
              aria-label="Puhelin palvelunumerot"
              width="100%"
          >
            Puhelin palvelunumerot
            <v-icon small>mdi-arrow-bottom-right</v-icon>
          </v-btn>
          <div class="mt-2" v-if="audioServicesMenu">
            <v-row dense>
              <v-col class="pb-0">
                <v-text-field v-model="menuKeyword" outlined label="Hae numeroa nimestä ja kuvauksesta"/>
              </v-col>
              <v-col cols="2" class="pb-0">
                <v-btn
                    class="v-btn ma-3 green float-right"
                    small
                    @click="getMenu('sms_marketing/audio_services', 'audio_services', menuKeyword)"
                >
                  Hae
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3" class="pt-0 pb-0">
                Soittajat numeroihin aikaväliltä:
              </v-col>
              <v-col class="pt-0 pb-0">
                <date-area-picker :input="startDate" parse-input="DD.MM.YYYY" @pick="startDate = $event" label="alku"/>
              </v-col>
              <v-col class="pt-0 pb-0">
                <date-area-picker :input="endDate" parse-input="DD.MM.YYYY" @pick="endDate = $event" label="loppu"/>
              </v-col>
            </v-row>

            <v-skeleton-loader
                v-if="apiCall"
                class="mx-auto"
                type="article"
            />

            <v-simple-table v-if="menuGroups.audio_services.length > 0 && !apiCall" height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Palvelunumero
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(menu, key) in menuGroups['audio_services']" :key="'menu3'+key" :class="{'secondary-row': key % 2 === 0, 'selected-row': key % 2 === 0 && menuGroupsSelectionCheckboxes.audio_services[key], 'selected-row2': key % 1 === 0 && menuGroupsSelectionCheckboxes.audio_services[key]}">
                    <td>
                      <label :for="'groups3'+key">
                        {{ menu.name }} {{ menu.description }}
                      </label>
                    </td>
                    <td>
                      <v-checkbox v-model="menuGroupsSelectionCheckboxes.audio_services[key]" @click="selection(menu, 'audio_services', key)" :id="'groups3'+key"></v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <!-- sms_service_numbers -->
          <v-btn
              @click="smsServiceNumbersMenu?smsServiceNumbersMenu=false:smsServiceNumbersMenu=true"
              class="not-selected-btn2 mt-1"
              :class="{'selected-btn': smsServiceNumbersMenu}"
              aria-label="SMS palvelunumerot"
              width="100%"
          >
            SMS palvelunumerot
            <v-icon small>mdi-arrow-bottom-right</v-icon>
          </v-btn>
          <div class="mt-2" v-if="smsServiceNumbersMenu">
            <v-row dense>
              <v-col cols="3" class="pt-0 pb-0">
                SMS viestijät numeroihin aikaväliltä:
              </v-col>
              <v-col class="pt-0 pb-0">
                <date-area-picker :input="startDate" parse-input="DD.MM.YYYY" @pick="startDate = $event" label="alku"/>
              </v-col>
              <v-col class="pt-0 pb-0">
                <date-area-picker :input="endDate" parse-input="DD.MM.YYYY" @pick="endDate = $event" label="loppu"/>
              </v-col>
            </v-row>

            <v-skeleton-loader
                v-if="apiCall"
                class="mx-auto"
                type="article"
            />

            <v-simple-table v-if="menuGroups.sms_service_numbers.length > 0 && !apiCall" height="300px">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Palvelunumero
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(menu, key) in menuGroups['sms_service_numbers']" :key="'menua'+key" :class="{'secondary-row': key % 2 === 0, 'selected-row': key % 2 === 0 && menuGroupsSelectionCheckboxes.sms_service_numbers[key], 'selected-row2': key % 1 === 0 && menuGroupsSelectionCheckboxes.sms_service_numbers[key]}">
                  <td>
                    <label :for="'groupsa'+key">
                      {{ menu.name }}
                    </label>
                  </td>
                  <td>
                    <v-checkbox v-model="menuGroupsSelectionCheckboxes.sms_service_numbers[key]" @click="selection(menu, 'sms_service_numbers', key)" :id="'groupsa'+key"></v-checkbox>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <!-- categories -->
          <v-btn
              @click="categoriesMenu?categoriesMenu=false:categoriesMenu=true"
              class="not-selected-btn mt-1"
              :class="{'selected-btn': categoriesMenu}"
              aria-label="Kategoriat"
              width="100%"
          >
            Kategoriat
            <v-icon small>mdi-arrow-bottom-right</v-icon>
          </v-btn>
          <div class="mt-2" v-if="categoriesMenu">
            <v-row dense>
              <v-col class="pb-0">
                <v-text-field v-model="menuKeyword" outlined label="Hae kategorian nimellä"/>
              </v-col>
              <v-col cols="2" class="pb-0">
                <v-btn
                    class="v-btn ma-3 green float-right"
                    small
                    @click="getMenu('sms_marketing/categories', 'categories', menuKeyword)"
                >
                  Hae
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3" class="pt-0 pb-0">
                Soittajat kategorioihin aikaväliltä:
              </v-col>
              <v-col class="pt-0 pb-0">
                <date-area-picker :input="startDate" parse-input="DD.MM.YYYY" @pick="startDate = $event" label="alku"/>
              </v-col>
              <v-col class="pt-0 pb-0">
                <date-area-picker :input="endDate" parse-input="DD.MM.YYYY" @pick="endDate = $event" label="loppu"/>
              </v-col>
            </v-row>

            <v-skeleton-loader
                v-if="apiCall"
                class="mx-auto"
                type="article"
            />

            <v-simple-table v-if="menuGroups.categories.length > 0 && !apiCall" height="300px">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Kategoria
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(menu, key) in menuGroups['categories']" :key="'menu3'+key" :class="{'secondary-row': key % 2 === 0, 'selected-row': key % 2 === 0 && menuGroupsSelectionCheckboxes.categories[key], 'selected-row2': key % 1 === 0 && menuGroupsSelectionCheckboxes.categories[key]}">
                  <td>
                    <label :for="'groups4'+key">
                      {{ menu.name }} {{ menu.description }}
                    </label>
                  </td>
                  <td>
                    <v-checkbox v-model="menuGroupsSelectionCheckboxes.categories[key]" @click="selection(menu, 'categories', key)" :id="'groups4'+key"></v-checkbox>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <!-- mainosluvan antaneet ad_attribute -->
          <v-btn
              @click="adsMenu?adsMenu=false:adsMenu=true"
              class="not-selected-btn2 mt-1"
              :class="{'selected-btn': adsMenu}"
              aria-label="Mainosluvan antaneet"
              width="100%"
          >
            Mainosluvan antaneet
            <v-icon small>mdi-arrow-bottom-right</v-icon>
          </v-btn>
          <div class="mt-2" v-if="adsMenu">
            <v-row dense>
              <v-col cols="3" class="pt-0 pb-0">
                Mainosluvan antaneet aikaväliltä:
              </v-col>
              <v-col class="pt-0 pb-0">
                <date-area-picker :input="startDate" parse-input="DD.MM.YYYY" @pick="startDate = $event" label="alku"/>
              </v-col>
              <v-col class="pt-0 pb-0">
                <date-area-picker :input="endDate" parse-input="DD.MM.YYYY" @pick="endDate = $event" label="loppu"/>
              </v-col>
            </v-row>

            <v-skeleton-loader
                v-if="apiCall"
                class="mx-auto"
                type="article"
            />

            <v-simple-table v-if="menuGroups.ad_attribute.length > 0 && !apiCall" height="300px">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Attribuutti
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(menu, key) in menuGroups['ad_attribute']" :key="'menu3'+key" :class="{'secondary-row': key % 2 === 0, 'selected-row': key % 2 === 0 && menuGroupsSelectionCheckboxes.ad_attribute[key], 'selected-row2': key % 1 === 0 && menuGroupsSelectionCheckboxes.ad_attribute[key]}">
                  <td>
                    <label :for="'groups6'+key">
                      {{ menu.info }}
                    </label>
                  </td>
                  <td>
                    <v-checkbox v-model="menuGroupsSelectionCheckboxes.ad_attribute[key]" @click="selection(menu, 'ad_attribute', key)" :id="'groups6'+key"></v-checkbox>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <!-- Poissaolevat asiakkaat -->
          <v-btn
              @click="missingCustomersMenu?missingCustomersMenu=false:missingCustomersMenu=true"
              class="not-selected-btn mt-1"
              :class="{'selected-btn': missingCustomersMenu}"
              aria-label="Epäaktiivisen asiakkaat"
              width="100%"
          >
            Epäaktiiviset asiakkaat
            <v-icon small>mdi-arrow-bottom-right</v-icon>
          </v-btn>
          <div class="mt-2" v-if="missingCustomersMenu">
            <v-row dense>
              <v-col class="pt-0 pb-0">
                Valitse epäaktiiviset asiakkaat aikaväliltä:
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pt-0 pb-0">
                <date-area-picker :input="startDate" parse-input="DD.MM.YYYY" @pick="startDate = $event" label="alku"/>
              </v-col>
              <v-col class="pt-0 pb-0">
                <date-area-picker :input="endDate" parse-input="DD.MM.YYYY" @pick="endDate = $event" label="loppu"/>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pt-0 pb-0">
                <v-select
                    v-model="inactiveAudience"
                    label="Kohdeyleisö"
                    outlined
                    :items="[
                      'Viihde',
                      'Asiantuntija',
                    ]"/>
              </v-col>
              <v-col class="pt-0 pb-0">
                <v-select
                    v-model="inactiveContact"
                    label="Kontaktikanava"
                    outlined
                    :items="[
                      'audio',
                      'SMS',
                    ]"/>
              </v-col>
              <v-col class="pt-0 pb-0 pt-3 text-center">
                  <v-btn
                      class="v-btn green"
                      @click="menuGroupsSelections.push({
                        id: startDate+endDate+inactiveAudience+inactiveContact,
                        group: 'missing_customers',
                        name: inactiveAudience+' '+inactiveContact,
                        from: startDate,
                        to: endDate,
                        type: inactiveAudience == 'Viihde' ? 1 : '2',
                        contact_type: inactiveContact,
                      });
                      getItemCount({
                        id: startDate+endDate+inactiveAudience+inactiveContact,
                        group: 'missing_customers',
                        from: startDate,
                        to: endDate,
                        type: inactiveAudience == 'Viihde' ? 1 : '2',
                        contact_type: inactiveContact,
                      })
                      "
                  >
                    <v-icon>mdi-plus</v-icon>
                    Lisää
                  </v-btn>
              </v-col>
            </v-row>
          </div>

          <!-- Manuaalinen numero -->
          <v-btn
              @click="customNumberMenu?customNumberMenu=false:customNumberMenu=true"
              class="not-selected-btn2 mt-1"
              :class="{'selected-btn': customNumberMenu}"
              aria-label="Valitse numero manuaalisesti"
              width="100%"
          >
            Lisää numero manuaalisesti
            <v-icon small>mdi-arrow-bottom-right</v-icon>
          </v-btn>
          <div class="mt-2" v-if="customNumberMenu">
            <v-row dense>
              <v-col class="pt-0 pb-0">
                Lisää numero manuaalisesti. Voi olla monta numeroa.
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pt-0 pb-0">
                <v-text-field v-model="manualNumber" outlined label="Numero"></v-text-field>
              </v-col>
              <v-col class="pt-0 pb-0 pt-3 text-center">
                <v-btn
                    class="v-btn green"
                    @click="menuGroupsSelections.push({
                        id: manualNumber,
                        group: 'manual',
                        name: manualNumber,
                        params: [manualNumber]
                      });
                      getItemCount({
                        id: manualNumber,
                        group: 'manual',
                        name: manualNumber,
                        params: manualNumber
                      })
                      "
                >
                  <v-icon>mdi-plus</v-icon>
                  Lisää
                </v-btn>
              </v-col>
            </v-row>
          </div>

        </v-col>
        <v-col cols="6">
          <h3>Valitut ryhmät:</h3>
          <v-simple-table v-if="menuGroupsSelections.length > 0" height="750px">
            <thead>
            <tr>
              <th class="text-left">
                Ryhmä
              </th>
              <th class="text-left">
                Tiedot
              </th>
              <th colspan="2">
                Numeroita
              </th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(selection, key) in menuGroupsSelections" :key="'menuselectiongroup'+key" class="selected-row2" :class="{'selected-row': key % 2 === 0}">
                <template v-if="selection">
                  <td>
                    <template v-if="selection.group == 'sms_marketing'">Kampanja</template>
                    <template v-if="selection.group == 'groups'">Ryhmä</template>
                    <template v-if="selection.group == 'audio_services'">Palvelunro</template>
                    <template v-if="selection.group == 'categories'">Kategoria</template>
                    <template v-if="selection.group == 'sms_service_numbers'">SMS Palvelunro</template>
                    <template v-if="selection.group == 'ad_attribute'">Mainoslupa</template>
                    <template v-if="selection.group == 'missing_customers'">Epäaktiivinen</template>
                    <template v-if="selection.group == 'manual'">Manuaalinen</template>
                  </td>
                  <td>
                    <template v-if="selection.text">{{ selection.text }}</template>
                    <template v-if="selection.name">{{ selection.name }}</template>
                    <template v-if="selection.info">{{ selection.info }}</template>
                    <template v-if="selection.group_type"> ({{ selection.group_type.type }})</template>
                  </td>
                  <td>
                    <v-skeleton-loader type="text" v-if="!selection.count && countApi" loading-text="lataa" light loading/>
                    <template v-else>
                      {{ selection.count }}
                    </template>
                  </td>
                  <td>
                    <v-icon color="red" @click="deleteFromMenuGroupsSelections(selection)">mdi-close</v-icon>
                  </td>
                </template>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="1">
                  Ryhmiä: {{ menuGroupsSelections.length }}
                </td>
                <td colspan="3">
                  Numeroita: {{ menuGroupsSelectionsCountSumVal }}
                </td>
              </tr>
            </tfoot>
          </v-simple-table>
          <div v-else>
            Ei vielä mitään. Valitse vasemmalta.
          </div>
        </v-col>
      </v-row>

      <!-- 4. Action buttons and results -->
      <v-divider class="mb-10 mt-5"></v-divider>
      <v-row>
        <v-col cols="12" align="center">
          <v-alert class="ma-auto ma-4" v-if="submitSuccess" width="500" type="info">
            {{submitSuccess}}
          </v-alert>
          <v-alert class="ma-auto ma-4" v-if="submitError" width="500"  type="error">
            {{submitError}}
          </v-alert>
        </v-col>
        <v-col>
          <v-btn
              class="v-btn green"
              @click="submit"
          >
            <template v-if="mode == 'edit'">Muokkaa kampanjaa</template>
            <template v-else>Tallenna uusi kampanja</template>
          </v-btn>

        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <router-link to="/direct_marketings">
            <v-icon>mdi-chevron-double-left</v-icon> Takaisin kampanjalistaan
          </router-link>
        </v-col>
      </v-row>
    </v-form>
    <!-- snackbar message -->
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbarTimeout"
        :color="snackbarColor"
    >
      <div class="text-center font-weight-bold">
        {{ snackbarMessage }}
      </div>
    </v-snackbar>
  </main>
</template>

<style scoped>
@keyframes rollDown {
  0% { height: 0; }
  100% { height: 100px; } /* Adjust the final height as needed */
}

@keyframes rollUp {
  0% { height: 100px; } /* Adjust the initial height to match the final height of the expand animation */
  100% { height: 0; }
}

.roll-enter-active {
  transition: opacity 0.5s;
  animation: rollDown 0.3s ease-out;
}

.roll-leave-active {
  transition: opacity 0.3s;
  animation: rollUp 0.3s ease-out;
}

.roll-enter, .roll-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.roll-down-enter-active {
  animation: rollDown 0.5s ease-out;
}

.roll-up-leave-active {
  animation: rollUp 0.5s ease-out;
}
.secondary-row {
  background-color: #414141;
}
.selected-row{
  background-color: #696b55 !important;
}
.selected-row2{
  background-color: #4d4f40;
}
.not-selected-btn {
  background-color: #378c00 !important;
}
.not-selected-btn2 {
  background-color: #235b00 !important;
}
.selected-btn{
  background-color: #f55726 !important;
}
</style>